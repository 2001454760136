exports.linkResolver = (doc) => {
  // URL for a page type
  if (doc?.type === "page") {
    return `/${doc.uid}`;
  }

  if (doc?.type === "newsroom") {
    return `/newsroom`;
  }

  if (doc?._meta?.type === "newsroom") {
    return `/newsroom`;
  }

  if (doc?._meta?.type === "category") {
    return `/newsroom?category=${doc?._meta?.uid}`;
  }

  if (doc?.type === "general_content_page") {
    return `/${doc.uid}`;
  }

  if (doc?.type === "for_employees") {
    return `/for-employees`;
  }

  // Backup for all other types
  return "/";
};
